import React from "react"
// import LinkTransition from '../common/link-transition';

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Section } from '../components/common/section';
import ContentHero from '../components/content-hero';
import ContentWyswyg from '../components/content-wyswyg';

const ContentSample = () => (
  <Layout
    socialDark={true}
    currentPage='jobs'
  >
    <SEO title="Jobs" />

    <Section>
      <ContentHero
        title="Jobs"
      />
    </Section>

    <Section>
      <ContentWyswyg
        content={[
          {
            type: 'text',
            html: `
              <h2>No content</h2>
            `
          }
        ]}
      />
    </Section>

    
  </Layout>
)

export default ContentSample;
